$('.slide-daily').owlCarousel({
    items:1,
    pagination: true,
    navText:["",""],
    responsiveClass:true,
    loop:true,
    margin:30,
    nav:true,
    //navText: ["","<img src='/templates/current/img/icon/next.png'>"],
    responsive:{
        0:{
            items:1
        },
        568:{
            items:2
        },
        768:{
            items:2
        },
        1000:{
            items:3
        }
    }
});
$('.slide-special').owlCarousel({
    items:1,
    pagination: true,
    navText:["",""],
    responsiveClass:true,
    loop:true,
    margin:30,
    nav:true,
    //navText: ["","<img src='/templates/current/img/icon/next.png'>"],
    responsive:{
        0:{
            items:1
        },
        568:{
            items:2
        },
        768:{
            items:2
        },
        1000:{
            items:3
        }
    }
});
$('.slide-similar').owlCarousel({
    items:1,
    pagination: true,
    navText:["",""],
    responsiveClass:true,
    loop:true,
    margin:30,
    nav:true,
    //navText: ["","<img src='/templates/current/img/icon/next.png'>"],
    responsive:{
        0:{
            items:1
        },
        568:{
            items:1
        },
        768:{
            items:2
        },
        1000:{
            items:3
        }
    }
});